import { useTranslation } from "react-i18next";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useState, Fragment } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { toast } from "react-toastify";

import useModalsStore from "../store/modalsStore";
import useUserStore from "../store/userStore";
import { queryClient } from "..";

import Logo from "../assets/logo";
import {
    BankCard,
    Chevron,
    DiscountCoupon,
    Earth,
    Favorite,
    Logout,
    Medal,
    RetiredMilitary,
    SavedAddresses,
    Share,
    Star,
    Orders,
    Camera,
    Edit,
} from "../assets/icons";
import { resetSession } from "../lib/middleware";

const linkData = [
    {
        to: "/account/orders",
        bgColor: "bg-white",
        Component: Orders,
        text: "account.orders",
    },
    {
        to: "/account/my-points",
        bgColor: "bg-[rgba(255,253,221,1)]",
        Component: Star,
        text: "account.my-points",
    },
    {
        to: "/account/edit-profile",
        bgColor: "bg-[rgba(241,255,247,1)]",
        Component: Edit,
        text: "account.edit",
    },
];

const links = [
    { to: "/account/favourite", icon: <Favorite />, text: "account.favourite" },
    {
        to: "/account/addresses",
        icon: <SavedAddresses />,
        text: "account.saved-addresses",
    },
    { to: "/account", icon: <BankCard />, text: "account.bank-cards" },
    {
        to: "/account",
        icon: <DiscountCoupon />,
        text: "account.discount-coupons",
    },
    {
        to: "/account/retired-military",
        icon: <RetiredMilitary />,
        text: "account.retired-military-association",
    },
    { to: "/account/share-app", icon: <Share />, text: "account.share-app" },
    {
        to: "/account/change-language",
        icon: <Earth />,
        text: "account.change-language",
    },
];

export default function Account() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const { user, setUser } = useUserStore();
    const { setModalContent, setModalOpen } = useModalsStore();

    const handleLogout = async () => {
        setLoading(true);
        setUser(null);
        try {
            await axios.get("/user/logout");
            toast.success(t("account.success-logout"));
            setModalOpen(false);
            resetSession();
            navigate("/");
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleLogoutModal = () => {
        setModalContent({
            title: (
                <>
                    {t("do-you-want")}{" "}
                    <span className="font-semibold">{t("logout")}</span>{" "}
                    {t("?")}
                </>
            ),
            confirmText: t("yes"),
            cancelText: t("no"),
            onConfirm: handleLogout,
            variant: "wide-btns",
            onCancel: () => setModalOpen(false),
            loading,
            icon: <Logo className="size-[63px]" />,
        });
        setModalOpen(true);
        resetSession();
    };

    return (
        <div className="container flex max-xl:flex-col">
            <div className="flex max-w-[392px] flex-col overflow-hidden py-[60px] xl:me-[100px]">
                <div className="mb-12 flex items-center gap-4">
                    <Link
                        to="/account"
                        className="relative flex shrink-0 items-center justify-center"
                    >
                        <img
                            src={user?.image}
                            alt={user?.first_name}
                            className="h-[98px] w-[98px] rounded-full border border-[rgba(11,76,36,0.4)] object-cover"
                        />
                        <div className="absolute bottom-0 end-0 overflow-hidden rounded-full border-[2.8px] border-[rgba(238,245,241,1)] bg-primary p-2">
                            <Camera />
                        </div>
                    </Link>
                    <div className="flex flex-col gap-0.5">
                        <span className="text-2xl font-semibold leading-tight text-[rgba(38,38,38,1)]">
                            {user?.first_name} {user?.last_name}
                        </span>
                        <span className="text-lg text-[rgba(38,38,38,1)]">
                            +{user?.country_code}
                            {user?.mobile}
                        </span>
                    </div>
                </div>
                <div className="mb-4 grid gap-4 xl:grid-cols-3">
                    {linkData.map(({ to, bgColor, Component, text }, index) => (
                        <Link
                            key={index}
                            to={to}
                            className={`flex flex-col items-center justify-center gap-2.5 text-nowrap rounded-xl ${bgColor} px-8 py-7 text-center shadow-[0px_2px_4px_0px_rgba(182,182,182,0.15)] transition hover:opacity-80`}
                        >
                            <Component />
                            <span className="font-medium text-[rgba(38,38,38,1)]">
                                {t(text)}
                            </span>
                        </Link>
                    ))}
                </div>
                <Link
                    to="/account/special-customer"
                    className="mb-4 flex items-center rounded-xl bg-white p-3 shadow-[0px_2px_4px_0px_rgba(182,182,182,0.15)] transition hover:opacity-80"
                >
                    <Medal />
                    <span className="h-full text-[rgba(48,48,48,1)]">
                        {t("account.special-customer")}
                    </span>
                    <Chevron />
                </Link>
                <Link
                    to="/account"
                    className="mb-4 flex items-center gap-2 rounded-xl bg-white p-3 shadow-[0px_2px_4px_0px_rgba(182,182,182,0.15)] transition hover:opacity-80"
                >
                    <Share />
                    <div className="flex flex-1 flex-col">
                        <span className="text-lg font-medium text-[rgba(38,38,38,1)]">
                            {t("account.get-points")}
                        </span>
                        <span className="font-light text-[rgba(82,82,82,1)]">
                            {t("account.get-points-desc")}
                        </span>
                    </div>
                    <Chevron />
                </Link>
                <div className="flex flex-col gap-2 rounded-xl bg-white p-4 shadow-[0px_2px_4px_0px_rgba(182,182,182,0.15)]">
                    {links.map(({ to, icon, text }, index) => (
                        <Fragment key={index}>
                            <Link
                                to={to}
                                className="flex w-full items-center gap-2 px-2 py-3 transition hover:opacity-80"
                            >
                                {icon}
                                <span className="h-full text-[rgba(38,38,38,1)]">
                                    {t(text)}
                                </span>
                                <Chevron />
                            </Link>
                            <hr className="border-t-[0.5px] border-[rgba(212,212,212,1)]" />
                        </Fragment>
                    ))}
                    <button
                        onClick={handleLogoutModal}
                        className="flex w-full items-center gap-2 px-2 py-3 transition hover:opacity-80"
                    >
                        <Logout />
                        <span className="h-full text-[rgba(240,61,67,1)]">
                            {t("account.logout")}
                        </span>
                        <Chevron />
                    </button>
                </div>
            </div>
            <div className="flex-1 bg-white py-[60px] shadow-[1000px_0px_0px_rgb(255_255_255)] xl:col-span-2 rtl:shadow-[-1000px_0px_0px_rgb(255_255_255)]">
                <Outlet />
            </div>
        </div>
    );
}
