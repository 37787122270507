import { cn } from "../../lib/utils";
import { useTranslation } from "react-i18next";
import useModalsStore from "../../store/modalsStore";
import useUserStore from "../../store/userStore";
import { setSession } from "../../lib/middleware";
import { useQuery } from "@tanstack/react-query";
import { getCart } from "../../actions/getCart";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { stringify } from "querystring";

export default function DeleteCartModal() {
    const { isDeleteCartModalOpen, setDeleteCartModalOpen } = useModalsStore();
    const { t } = useTranslation();
    const user = useUserStore((state) => state.user);
    const changeAddress = useUserStore((state) => state.changeAddress);
    const setMainAddress = useUserStore((state) => state.setMainAddress);
    const navigate = useNavigate();

    const {
        data,
        isLoading,
        refetch: getCartItems,
    } = useQuery({
        queryKey: ["global", "cart"],
        queryFn: getCart,
    });

    const handleChange = async () => {
        getCartItems();
        if (data && data.length !== 0) {
            try {
                data.forEach((item: any) => {
                    const formData = new FormData();
                    formData.append("cart_id", item.id);
                    axios.post("/cart/empty", formData);
                });
                navigate("/home");
            } catch (error) {
                console.error("Error emptying cart:", error);
            }
        }
        setMainAddress(changeAddress);
        setSession();
        setDeleteCartModalOpen(!isDeleteCartModalOpen);
    };

    return (
        <div
            className={cn("flex flex-col", {
                hidden: !isDeleteCartModalOpen,
            })}
        >
            <div className="mb-8 flex justify-center">
                <img
                    src="/delete-icon.png"
                    alt="Warning"
                    className="h-10 w-10"
                />
            </div>
            <div className="flex flex-col gap-4">
                <span className="mb-8 text-center text-xl text-foreground">
                    {t("modals.clear-cart")}
                </span>
            </div>
            <br />
            <div className="flex gap-4">
                {" "}
                <button
                    className={cn(
                        "flex-1 rounded-md border border-[rgba(212,212,212,1)] border-primary bg-white px-7 py-4 font-medium text-primary",
                    )}
                    onClick={() =>
                        setDeleteCartModalOpen(!isDeleteCartModalOpen)
                    }
                >
                    {t("modals.cancel")}
                </button>
                <button
                    className={cn(
                        "flex-1 rounded-xl bg-primary py-4 text-white transition hover:opacity-80",
                    )}
                    onClick={handleChange}
                >
                    {t("modals.change")}
                </button>
            </div>
        </div>
    );
}
