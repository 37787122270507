import { useCallback, useEffect, useState } from "react";
import {
    IResolveParams,
    LoginSocialApple,
    LoginSocialFacebook,
    LoginSocialGoogle,
} from "reactjs-social-login";
import useModalsStore from "../../store/modalsStore";
import { SOCIAL_LOGIN_URL } from "../../lib/urls";
import { toast } from "react-toastify";
import useUserStore from "../../store/userStore";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { setToken } from "../../lib/middleware";

const REDIRECT_URI = window.location.href;

export default function SocialLogin() {
    const closeModal = useModalsStore((state) => state.closeModal);
    const [provider, setProvider] = useState("");
    const [profile, setProfile] = useState<any>();

    const onLogoutSuccess = useCallback(() => {
        setProfile(null);
        setProvider("");
        // alert("logout success");
    }, []);

    const { t } = useTranslation();
    const setMapModalOpen = useModalsStore((state) => state.setMapModalOpen);

    const setUser = useUserStore((state) => state.setUser);
    async function login() {
        try {
            const formData = new FormData();
            formData.append("social_type", provider);
            formData.append("device_type", "web");
            if (profile.id) formData.append("social_profile_id", profile.id);
            else formData.append("social_profile_id", "1000");
            formData.append("social_token", profile.access_token);
            const res = await axios.post("/user/login/social", formData);
            const data = res.data;
            setUser(data.data);
            setToken(data.data.token);
            localStorage.setItem("user", JSON.stringify(data.data));
            toast.success(t("messages.login-success"));
            closeModal();
            if (localStorage.getItem("firstVisit")) {
                setMapModalOpen(true);
            }
        } catch (err) {
            console.log(err);
        }
    }
    useEffect(() => {
        if (profile) {
            console.log(profile);
        }
        if (provider) {
            console.log(provider);
        }
    }, [profile, provider]);

    return (
        <div className="grid w-full grid-cols-3 gap-2">
            <LoginSocialFacebook
                isOnlyGetToken
                appId={process.env.REACT_APP_FB_APP_ID || ""}
                // onLoginStart={onLoginStart}
                onResolve={({ provider, data }: IResolveParams) => {
                    setProvider(provider);
                    setProfile(data);
                    login();
                }}
                onReject={(err) => {
                    console.log(err);
                }}
                fieldsProfile="id"
            >
                <button className="flex w-full items-center justify-center rounded-lg border border-[rgba(229,229,229,1)] py-[27px] transition hover:opacity-80">
                    <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M18.2118 30.7689V17.317H22.5859L23.2361 12.0501H18.2118V8.6953C18.2118 7.17548 18.62 6.1349 20.7224 6.1349H23.3864V1.43919C22.0902 1.29507 20.7873 1.22548 19.4837 1.23075C15.6174 1.23075 12.9628 3.67954 12.9628 8.17502V12.0402H8.61719V17.3071H12.9723V30.7689H18.2118Z"
                            fill="#4092FF"
                        />
                    </svg>
                </button>
            </LoginSocialFacebook>
            <LoginSocialGoogle
                isOnlyGetToken
                scope="https://www.googleapis.com/auth/userinfo.profile openid https://www.googleapis.com/auth/userinfo.email"
                client_id={process.env.REACT_APP_GG_APP_ID || ""}
                onResolve={async ({ provider, data }: IResolveParams) => {
                    setProvider(provider);
                    setProfile(data);
                    login();
                }}
                onReject={(err) => {
                    console.log(err);
                }}
            >
                <button className="flex w-full items-center justify-center rounded-lg border border-[rgba(229,229,229,1)] py-[27px] transition hover:opacity-80">
                    <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M7.9081 19.0325L6.89548 22.8127L3.1944 22.891C2.08832 20.8394 1.46094 18.4922 1.46094 15.9979C1.46094 13.5859 2.04753 11.3114 3.0873 9.30859H3.0881L6.3831 9.91268L7.82651 13.1879C7.5244 14.0686 7.35975 15.0141 7.35975 15.9979C7.35986 17.0656 7.55327 18.0886 7.9081 19.0325Z"
                            fill="#FBBB00"
                        />
                        <path
                            d="M30.2997 13.2812C30.4667 14.1611 30.5538 15.0698 30.5538 15.9985C30.5538 17.0399 30.4443 18.0557 30.2357 19.0355C29.5277 22.3698 27.6775 25.2812 25.1145 27.3416L25.1137 27.3408L20.9635 27.129L20.3761 23.4623C22.0768 22.4649 23.4059 20.904 24.106 19.0355H16.3281V13.2812H24.2194H30.2997Z"
                            fill="#518EF8"
                        />
                        <path
                            d="M25.1126 27.3389L25.1134 27.3397C22.6207 29.3433 19.4542 30.5421 16.0073 30.5421C10.468 30.5421 5.65207 27.446 3.19531 22.8898L7.909 19.0312C9.13736 22.3095 12.2998 24.6432 16.0073 24.6432C17.6009 24.6432 19.0938 24.2124 20.3749 23.4604L25.1126 27.3389Z"
                            fill="#28B446"
                        />
                        <path
                            d="M25.2893 4.80176L20.5772 8.65949C19.2514 7.83074 17.6841 7.35199 16.005 7.35199C12.2136 7.35199 8.99202 9.79273 7.8252 13.1886L3.08673 9.30926H3.08594C5.50673 4.64193 10.3834 1.45312 16.005 1.45312C19.5343 1.45312 22.7703 2.71028 25.2893 4.80176Z"
                            fill="#F14336"
                        />
                    </svg>
                </button>{" "}
            </LoginSocialGoogle>
            {/* <LoginSocialApple
                client_id={process.env.REACT_APP_APPLE_ID || ""}
                scope={"name email"}
                redirect_uri={REDIRECT_URI}
                onLoginStart={onLoginStart}
                onResolve={({ provider, data }: IResolveParams) => {
                    setProvider(provider);
                    setProfile(data);
login();
                }}
                onReject={(err) => {
                    console.log(err);
                }}
            > */}
            <button className="flex w-full items-center justify-center rounded-lg border border-[rgba(229,229,229,1)] py-[27px] transition hover:opacity-80">
                <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_258_154492)">
                        <path
                            d="M9.443 31.402C8.81209 30.9823 8.25293 30.4636 7.787 29.866C7.27449 29.2492 6.79962 28.602 6.365 27.928C5.34508 26.4338 4.54524 24.8008 3.99 23.079C3.323 21.079 3 19.162 3 17.287C3 15.193 3.453 13.365 4.339 11.829C4.99578 10.6284 5.96141 9.62517 7.136 8.923C8.27431 8.21597 9.58224 7.82905 10.922 7.803C11.391 7.803 11.896 7.871 12.432 8.001C12.817 8.11 13.286 8.282 13.859 8.496C14.588 8.777 14.989 8.949 15.125 8.991C15.552 9.147 15.911 9.215 16.193 9.215C16.407 9.215 16.709 9.147 17.052 9.043C17.245 8.975 17.609 8.855 18.13 8.632C18.646 8.444 19.052 8.283 19.375 8.163C19.87 8.017 20.349 7.882 20.776 7.814C21.2822 7.73419 21.7959 7.71305 22.307 7.751C23.1926 7.80888 24.0649 7.99656 24.896 8.308C26.255 8.855 27.354 9.709 28.172 10.923C27.8263 11.1361 27.5018 11.3819 27.203 11.657C26.5526 12.2336 25.9986 12.9105 25.562 13.662C24.9913 14.6891 24.6954 15.8461 24.703 17.021C24.724 18.464 25.094 19.735 25.823 20.834C26.3589 21.6485 27.0555 22.3451 27.87 22.881C28.287 23.162 28.646 23.355 28.99 23.485C28.829 23.985 28.657 24.469 28.454 24.949C27.9936 26.0254 27.4356 27.0574 26.787 28.032C26.209 28.871 25.756 29.496 25.412 29.912C24.876 30.547 24.36 31.032 23.839 31.37C23.266 31.75 22.589 31.953 21.901 31.953C21.4351 31.9709 20.9692 31.9145 20.521 31.786C20.136 31.656 19.755 31.515 19.38 31.354C18.9901 31.1749 18.5881 31.0236 18.177 30.901C17.1607 30.64 16.0951 30.6382 15.078 30.896C14.661 31.016 14.26 31.156 13.864 31.328C13.307 31.562 12.937 31.719 12.723 31.786C12.296 31.911 11.853 31.989 11.405 32.015C10.712 32.015 10.066 31.817 9.426 31.416L9.443 31.402ZM18.583 6.787C17.677 7.24 16.812 7.433 15.953 7.37C15.818 6.505 15.953 5.62 16.312 4.651C16.6176 3.83007 17.0692 3.07118 17.645 2.411C18.253 1.71602 18.9908 1.14638 19.817 0.734C20.697 0.281 21.536 0.036 22.338 0C22.442 0.906 22.338 1.797 22.005 2.76C21.6968 3.61128 21.2461 4.40393 20.672 5.104C20.0861 5.79993 19.367 6.37179 18.557 6.786L18.583 6.787Z"
                            fill="black"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_258_154492">
                            <rect width="32" height="32" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </button>{" "}
            {/* </LoginSocialApple> */}
        </div>
    );
}
