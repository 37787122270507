import { Outlet } from "react-router-dom";
import Header from "../components/header/header";
import { useTranslation } from "react-i18next";
import useModalsStore from "../store/modalsStore";
import LanguageCountryModal from "../components/modals/language-country-modal";
import SelectPreviousAddressModal from "../components/modals/select-previous-address-modal";
import DeleteCartModal from "../components/modals/delete-cart-modal";
import ReactModal from "react-modal";
import { LoginForm } from "../components/forms/login-form";
import { RegisterForm } from "../components/forms/register-form";
import OtpVerification from "../components/forms/otp-verification";
import Footer from "../components/footer/footer";
import { useEffect } from "react";
import Modal from "../components/modals/modal";
import MapModal from "../components/modals/map-modal";
import ScrollToTop from "../components/scroll-to-top";
import { getSession, invalidate } from "../lib/middleware";

export default function Root() {
    const {
        isLanguageCountryModalOpen,
        isSelectPreviousAddressModalOpen,
        isDeleteCartModalOpen,
        isLoginModalOpen,
        isOtpModalOpen,
        isRegisterModalOpen,
        isModalOpen,
        setLanguageCountryModalOpen,
        closeModal,
        isMapModalOpen,
    } = useModalsStore();
    const { i18n } = useTranslation();

    useEffect(() => {
        const firstVisit = localStorage.getItem("firstVisit");

        if (!firstVisit) {
            i18n.changeLanguage("ar");
            document.dir = "rtl";
            setLanguageCountryModalOpen(true);
            localStorage.setItem("firstVisit", "true");
        }

        getSession();
    }, [i18n, setLanguageCountryModalOpen]);

    useEffect(() => {
        invalidate();
        if (i18n.language === "ar") {
            document.dir = "rtl";
        } else {
            document.dir = "ltr";
        }
    }, [i18n.language]);

    return (
        <>
            <ReactModal
                ariaHideApp={false}
                isOpen={
                    isLoginModalOpen ||
                    isRegisterModalOpen ||
                    isOtpModalOpen ||
                    isLanguageCountryModalOpen ||
                    isSelectPreviousAddressModalOpen ||
                    isDeleteCartModalOpen ||
                    isModalOpen ||
                    isMapModalOpen
                }
                onRequestClose={closeModal}
            >
                {isLoginModalOpen && <LoginForm />}
                {isRegisterModalOpen && <RegisterForm />}
                {isLanguageCountryModalOpen && <LanguageCountryModal />}
                {isSelectPreviousAddressModalOpen && (
                    <SelectPreviousAddressModal />
                )}
                {isDeleteCartModalOpen && <DeleteCartModal />}
                {isOtpModalOpen && <OtpVerification />}
                {isModalOpen && <Modal />}
                {isMapModalOpen && <MapModal />}
            </ReactModal>

            <ScrollToTop />

            <div className="flex min-h-screen flex-col">
                <Header />
                <div className="flex-1">
                    <Outlet />
                </div>
                <Footer />
            </div>
        </>
    );
}
