import axios, { InternalAxiosRequestConfig } from "axios";
import {
    getBranchId,
    getCountry,
    getEmirateId,
    getLanguage,
    getLat,
    getLng,
    getRegionId,
    getToken,
    logout,
} from "./middleware";

axios.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
        const token = getToken();
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        const country = getCountry();
        switch (country) {
            case "uae":
                config.baseURL = process.env.REACT_APP_BACKEND_URL_UAE;
                break;
            case "ksa":
                config.baseURL = process.env.REACT_APP_BACKEND_URL_KSA;
                config.headers["emirate-id"] = "1";
                break;
            case "om":
                config.baseURL = process.env.REACT_APP_BACKEND_URL_OM;
                break;
            default:
                config.baseURL = process.env.REACT_APP_BACKEND_URL_UAE;
        }
        const branchId = getBranchId();
        if (branchId) {
            config.headers["branch-id"] = branchId;
        } else {
            config.headers["branch-id"] = "8";
        }

        const emirateId = getEmirateId();
        if (emirateId) {
            config.headers["emirate-id"] = emirateId;
        }

        const regionId = getRegionId();
        if (regionId) {
            config.headers["region-id"] = regionId;
        }

        const language = getLanguage();
        if (language) {
            config.headers["lang"] = language;
        }

        const lat = getLat();
        if (lat) {
            config.headers["lat"] = lat;
        }

        const lng = getLng();
        if (lng) {
            config.headers["lng"] = lng;
        }

        config.headers["source"] = "web";

        return config;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            logout();
        }
        return Promise.reject(error);
    },
);

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            logout();
        }

        return Promise.reject(error);
    },
);
