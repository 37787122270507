import { useTranslation } from "react-i18next";

export default function PaymentMethod({
    paymentMethods,
    paymentMethod,
    setPaymentMethod,
}: {
    paymentMethods: string[];
    paymentMethod: string;
    setPaymentMethod: (method: string) => void;
}) {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col gap-4">
            <span className="text-2xl font-medium text-[rgba(38,38,38,1)]">
                {t("payment-method")}
            </span>
            <div className="flex flex-col gap-2 rounded-xl border border-[rgba(229,229,229,1)] bg-white p-4">
                {paymentMethods.map((method, idx) => {
                    // console.log("Payment method:", method);
                    if (String(method) === "2") {
                        return (
                            <div className="contents" key={idx}>
                                <button
                                    className="flex gap-2 py-3"
                                    onClick={() => setPaymentMethod("2")}
                                >
                                    <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        {paymentMethod === "2" && (
                                            <circle
                                                cx="8.99969"
                                                cy="9.00018"
                                                r="6.68182"
                                                fill="#0A4C26"
                                            />
                                        )}
                                        <circle
                                            cx="8.99991"
                                            cy="9.00089"
                                            r="8.35227"
                                            stroke="#0B4C24"
                                            strokeWidth="1.11364"
                                        />
                                    </svg>
                                    <div className="flex flex-1 flex-col items-start gap-3.5">
                                        <span className="leading-none text-[rgba(64,64,64,1)]">
                                            {t("credit-debit-card")}
                                        </span>
                                        <div className="flex flex-wrap gap-2">
                                            <img
                                                className="h-[40px] w-[60px]"
                                                src="/payment/VISA_logo.png"
                                                alt="Visa"
                                            />
                                            <img
                                                className="h-[40px] w-[60px]"
                                                src="/payment/PayPal_logo.png"
                                                alt="PayPal"
                                            />
                                            <img
                                                className="h-[40px] w-[60px]"
                                                src="/payment/mastercard_logo.png"
                                                alt="Mastercard"
                                            />
                                            <img
                                                className="h-[40px] w-[60px]"
                                                src="/payment/GooglePay_logo.png"
                                                alt="GooglePay"
                                            />
                                            <img
                                                className="h-[40px] w-[60px]"
                                                src="/payment/ApplePay_logo.png"
                                                alt="ApplePa"
                                            />
                                        </div>
                                    </div>
                                </button>
                                {idx !== paymentMethods.length - 1 && (
                                    <hr className="border-t-[0.5px] border-[rgba(178,178,178,0.32)]" />
                                )}
                            </div>
                        );
                    } else if (String(method) === "1") {
                        return (
                            <div className="contents" key={idx}>
                                <button
                                    className="flex gap-2 py-3"
                                    onClick={() => setPaymentMethod("1")}
                                >
                                    <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        {paymentMethod === "1" && (
                                            <circle
                                                cx="8.99969"
                                                cy="9.00018"
                                                r="6.68182"
                                                fill="#0A4C26"
                                            />
                                        )}
                                        <circle
                                            cx="8.99991"
                                            cy="9.00089"
                                            r="8.35227"
                                            stroke="#0B4C24"
                                            strokeWidth="1.11364"
                                        />
                                    </svg>
                                    <div className="flex flex-1 flex-col items-start gap-3.5">
                                        <span className="leading-none text-[rgba(64,64,64,1)]">
                                            {t("cash-on-delivery")}
                                        </span>
                                        <div className="flex flex-wrap gap-2">
                                            <img
                                                className="h-[40px] w-[60px]"
                                                src="/payment/CASH_logo.png"
                                                alt="Cash"
                                            />
                                        </div>
                                    </div>
                                </button>
                                {idx !== paymentMethods.length - 1 && (
                                    <hr className="border-t-[0.5px] border-[rgba(178,178,178,0.32)]" />
                                )}
                            </div>
                        );
                    } else if (String(method) === "6") {
                        return (
                            <div className="contents" key={idx}>
                                <button
                                    className="flex gap-2 py-3"
                                    onClick={() => setPaymentMethod("6")}
                                >
                                    <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        {paymentMethod === "6" && (
                                            <circle
                                                cx="8.99969"
                                                cy="9.00018"
                                                r="6.68182"
                                                fill="#0A4C26"
                                            />
                                        )}
                                        <circle
                                            cx="8.99991"
                                            cy="9.00089"
                                            r="8.35227"
                                            stroke="#0B4C24"
                                            strokeWidth="1.11364"
                                        />
                                    </svg>
                                    <div className="flex flex-1 flex-col items-start gap-3.5">
                                        <span className="leading-none text-[rgba(64,64,64,1)]">
                                            {t("tamara")}
                                        </span>
                                        <div className="flex flex-wrap gap-2">
                                            <img
                                                className="h-[40px] w-[60px]"
                                                src="/payment/Tamara_logo.png"
                                                alt="Tamara"
                                            />
                                        </div>
                                    </div>
                                </button>
                                {idx !== paymentMethods.length - 1 && (
                                    <hr className="border-t-[0.5px] border-[rgba(178,178,178,0.32)]" />
                                )}
                            </div>
                        );
                    } else return null;
                })}
            </div>
        </div>
    );
}
