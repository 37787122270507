import { useQuery } from "@tanstack/react-query";
import CategoriesSection from "../components/home/categories-section";
import Hero from "../components/home/hero";
import ProductsSection from "../components/home/products-section";
import { getHomePage } from "../actions/getHomePage";
import { useTranslation } from "react-i18next";
import HomeProductsSection from "../components/home/home-products-section";
import HomeShopsSection from "../components/home/home-shops-section";
import LoadingScreen from "../components/loading-screen";
import useUserStore from "../store/userStore";

export default function Home() {
    const { t } = useTranslation();
    const mainAddress = useUserStore((state) => state.mainAddress);
    const setMainAddress = useUserStore((state) => state.setMainAddress);
    const { data, isFetching } = useQuery({
        queryKey: ["global", "home-page"],
        queryFn: getHomePage,
    });
    if (isFetching) return <LoadingScreen />;
    if (!data) return null;
    return (
        <>
            <div className="container flex flex-col gap-y-10 py-10 xl:gap-y-20">
                <Hero sliders={data.slider} />
                <CategoriesSection categories={data.categories} />

                <div className="flex flex-col gap-y-10 xl:gap-y-[68px]">
                    {data.limited_offer && data.limited_offer.length > 0 && (
                        <ProductsSection
                            title={t("limited-offers")}
                            products={data.limited_offer}
                        />
                    )}

                    {data.home_sections.map((section) => {
                        if (
                            section.type === "product" &&
                            section.items.length > 0
                        ) {
                            return (
                                <HomeProductsSection
                                    key={section.name}
                                    items={section.items}
                                    title={section.name}
                                />
                            );
                        }
                        if (
                            section.type === "shop" &&
                            section.items.length > 0
                        ) {
                            return (
                                <HomeShopsSection
                                    key={section.name}
                                    items={section.items}
                                    title={section.name}
                                />
                            );
                        }
                        return null;
                    })}
                </div>
            </div>
        </>
    );
}
